<template>
  <div class="flex items-center">
    <gista-modal
      :value="isOpen"
      width="11/12"
			@input="toggle"
			customClass="bg-white top-0 bottom-0 mt-6 mb-8 max-w-screen-2xl h-full">
      <div class="h-full">
        <div class="flex flex-col items-center justify-center gap-3 mb-5 sm:mb-0 sm:h-16 sm:flex-row">
          <p class="py-5 text-xl font-semibold text-gray">Negotiation Details</p>
          <div class="w-full sm:absolute sm:py-4 sm:right-14 sm:w-max">
            <button-gista size="small" class="w-full sm:w-max" @click="toggleExport" v-if="menuRfpNegotiationExportXlsx?.canCreate">
              Export to Excel
            </button-gista>
          </div>
        </div>
        <div class="overflow-x-auto height-custom">
          <div class="w-full overflow-x-auto min-width-custom">
            <table class="w-full border-collapse" aria-describedby="maximize">
              <thead>
                <tr class="sticky top-0 z-50 h-10 bg-white">
                  <th scope="col" class="sticky left-0 z-40 h-10 p-0 text-sm truncate bg-white tdWidthClass1"><div class="h-full border-b-2 border-primary"></div></th>
                  <th scope="col" class="sticky z-40 h-10 p-0 text-sm truncate bg-white tdWidthClass2" :style="`left: ${tdWidth1}px;`">
                    <h5 class="border-b-2 border-primary font-medium flex items-center pr-2.5 h-10">
                      No
                    </h5>
                  </th>
                  <th scope="col" class="sticky z-40 h-10 p-0 text-sm truncate bg-white tdWidthClass3" :style="`left: ${tdWidth1+tdWidth2}px;`">
                    <h5 class="flex items-center h-10 pr-8 font-medium border-b-2 border-primary">
                      ID Product
                    </h5>
                  </th>
                  <th scope="col" class="sticky z-40 h-10 p-0 text-sm truncate bg-white tdWidthClass4" :style="`left: ${tdWidth1+tdWidth2+tdWidth3}px;`">
                    <h5 class="flex items-center h-10 pr-8 font-medium border-b-2 border-primary">
                      Type
                    </h5>
                  </th>
                  <th scope="col" class="sticky z-40 h-10 p-0 text-sm truncate bg-white" :style="`left: ${tdWidth1+tdWidth2+tdWidth3+tdWidth4}px;`">
                    <h5 class="h-10 p-0 font-medium border-b-2 border-primary">
                      <span class="flex items-center w-full h-full pr-8 border-r border-gray-lightest">
                        Product/Service
                      </span>
                    </h5>
                  </th>
                  <th scope="col" class="z-20 h-10 p-0 text-sm truncate bg-white">
                    <h5 class="flex items-center h-10 px-8 font-medium border-b-2 border-primary">
                      Sub Product
                    </h5>
                  </th>
                  <th scope="col" class="z-20 h-10 p-0 text-sm truncate bg-white">
                    <h5 class="flex items-center h-10 pr-8 font-medium border-b-2 border-primary">
                      Description
                    </h5>
                  </th>
                  <th scope="col" class="z-20 h-10 p-0 text-sm truncate bg-white ">
                    <h5 class="flex items-center h-10 pr-8 font-medium border-b-2 border-primary">
                      Location
                    </h5>
                  </th>
                  <th scope="col" class="z-20 h-10 p-0 text-sm truncate bg-white ">
                    <h5 class="flex items-center h-10 pr-8 font-medium border-b-2 border-primary">
                      Qty
                    </h5>
                  </th>
                  <th scope="col" class="z-20 h-10 p-0 text-sm truncate bg-white ">
                    <h5 class="flex items-center h-10 pr-8 font-medium border-b-2 border-primary">
                      UoM
                    </h5>
                  </th>
                  <th scope="col" class="z-20 h-10 p-0 text-sm truncate bg-white ">
                    <h5 class="flex items-center h-10 pr-8 font-medium border-b-2 border-primary">
                      Period
                    </h5>
                  </th>
                  <th scope="col" class="z-20 h-10 p-0 text-sm truncate bg-white ">
                    <h5 class="flex items-center h-10 pr-8 font-medium border-b-2 border-primary">
                      Currency
                    </h5>
                  </th>
                  <th scope="col" class="z-20 h-10 p-0 text-sm truncate bg-white ">
                    <h5 class="flex items-center justify-center h-10 pr-8 font-medium border-b-2 border-primary">
                      Monthly Cost
                    </h5>
                  </th>
                  <th scope="col" class="z-20 h-10 p-0 text-sm truncate bg-white ">
                    <h5 class="flex items-center justify-center h-10 pr-8 font-medium border-b-2 border-primary">
                      Unit Price
                    </h5>
                  </th>
                  <th scope="col" class="z-20 h-10 p-0 text-sm truncate bg-white ">
                    <h5 class="flex items-center justify-center h-10 pr-8 font-medium border-b-2 border-primary">
                      Total Unit Price
                    </h5>
                  </th>
                  <th scope="col" class="z-20 h-10 p-0 text-sm truncate bg-white ">
                    <h5 class="flex items-center justify-center h-10 pr-8 font-medium border-b-2 border-primary">
                      Disc %
                    </h5>
                  </th> 
                  <th scope="col" class="z-20 h-10 p-0 text-sm truncate bg-white ">
                    <h5 class="flex items-center justify-center h-10 pr-8 font-medium border-b-2 border-primary">
                      Disc Price
                    </h5>
                  </th>
                  <th scope="col" class="z-20 h-10 p-0 text-sm truncate bg-white ">
                    <h5 class="flex items-center justify-center h-10 pr-8 font-medium border-b-2 border-primary">
                      Final Total Price
                    </h5>
                  </th>
                </tr>
              </thead>
              <tbody v-for="n,i in negotiationDetails.offers" :key="i">
                <tr class="sticky z-40 cursor-pointer top-10 bg-primary-lightest" @click="changeCollapse(i)">
                  <td class="sticky left-0 z-20 p-0" colspan="1" >
                    <div class="flex items-center justify-center w-12 h-full">
                      <span v-if="!collapse[i]" class="text-base icon-minus text-gray"></span>
                      <span v-if="collapse[i]" class="text-base icon-plus text-gray"></span>
                    </div>
                  </td>
                  <td class="sticky z-20 h-10 p-0 pr-8" :style="`left: ${tdWidth1}px;`" colspan="4">
                    <p class="text-sm font-medium text-gray-darkest">{{ n.title }}</p>
                  </td>
                  <td colspan="13"></td>
                </tr>
                <template v-if="!collapse[i]">
                  <tr v-for="item,j in n.offerDetails" :key="j+'a'" class="text-center">
                    <td class="sticky left-0 z-20 h-10 p-0 pr-8 text-sm bg-white tdWidthClass1"></td>
                    <td class="sticky z-20 p-0 text-sm bg-white tdWidthClass2" :style="`left: ${tdWidth1}px;`">
                      <div class="flex items-center h-10 font-medium truncate">
                        {{ j+1 }}
                      </div>
                    </td>
                    <td class="sticky z-20 p-0 text-sm bg-white tdWidthClass3" :style="`left: ${tdWidth1+tdWidth2}px;`" >
                      <div class="flex items-center h-10 pr-8 font-medium truncate">
                        <p v-if="item.productId?.length > maxLength" class="font-medium text-gray-darkest">{{item.productId.slice(0,20)}}... <span class="cursor-pointer text-secondary" @click="readMore(item.productId)">Read More</span></p>
                        <p v-else class="font-medium text-gray-darkest">{{item.productId}}</p>
                      </div>
                    </td>
                    <td class="sticky z-20 p-0 text-sm bg-white tdWidthClass4" :style="`left: ${tdWidth1+tdWidth2+tdWidth3}px;`">
                      <div class="flex items-center h-10 pr-8 font-medium truncate">
                        <p v-if="item.jenis?.length > maxLength" class="font-medium text-gray-darkest">{{item.jenis.slice(0,20)}}... <span class="cursor-pointer text-secondary" @click="readMore(item.jenis)">Read More</span></p>
                        <p v-else class="font-medium text-gray-darkest">{{item.jenis}}</p>
                      </div>
                    </td>
                    <td class="sticky z-20 p-0 text-sm bg-white" :style="`left: ${tdWidth1+tdWidth2+tdWidth3+tdWidth4}px;`">
                      <div class="flex items-center h-10 pr-8 font-medium truncate border-r border-gray-lightest">
                        <p v-if="item.productLayanan?.length > maxLength" class="font-medium text-gray-darkest">{{item.productLayanan.slice(0,20)}}... <span class="cursor-pointer text-secondary" @click="readMore(item.productLayanan)">Read More</span></p>
                        <p v-else class="font-medium text-gray-darkest">{{item.productLayanan}}</p>
                      </div>
                    </td>
                    <td class="p-0 px-8 text-sm bg-white">
                      <div class="flex items-center h-10 pr-8 font-medium truncate">
                        <p v-if="item.subProduct?.length > maxLength" class="font-medium text-gray-darkest">{{item.subProduct.slice(0,20)}}... <span class="cursor-pointer text-secondary" @click="readMore(item.subProduct)">Read More</span></p>
                        <p v-else class="font-medium text-gray-darkest">{{item.subProduct}}</p>
                      </div>
                    </td>
                    <td class="p-0 text-sm bg-white">
                      <div class="flex items-center h-10 pr-8 font-medium truncate">
                        <p v-if="item.description?.length > maxLength" class="font-medium text-gray-darkest">{{item.description.slice(0,20)}}... <span class="cursor-pointer text-secondary" @click="readMore(item.description)">Read More</span></p>
                        <p v-else class="font-medium text-gray-darkest">{{item.description}}</p>
                      </div>
                    </td>
                    <td class="p-0 text-sm bg-white">
                      <div class="flex items-center h-10 pr-8 font-medium truncate">
                        <p v-if="item.location?.length > maxLength" class="font-medium text-gray-darkest">{{item.location.slice(0,20)}}... <span class="cursor-pointer text-secondary" @click="readMore(item.location)">Read More</span></p>
                        <p v-else class="font-medium text-gray-darkest">{{item.location}}</p>
                      </div>
                    </td>
                    <td class="p-0 text-sm bg-white">
                      <div class="flex items-center h-10 pr-8 font-medium truncate">
                        {{item.qty}}
                      </div>
                    </td>
                    <td class="p-0 text-sm bg-white">
                      <div class="flex items-center h-10 pr-8 font-medium truncate">
                        <p v-if="item.uom?.length > maxLength" class="font-medium text-gray-darkest">{{item.uom.slice(0,20)}}... <span class="cursor-pointer text-secondary" @click="readMore(item.uom)">Read More</span></p>
                        <p v-else class="font-medium text-gray-darkest">{{item.uom}}</p>
                      </div>
                    </td>
                    <td class="p-0 text-sm bg-white">
                      <div class="flex items-center h-10 pr-8 font-medium truncate">
                        <p v-if="item.period?.length > maxLength" class="font-medium text-gray-darkest">{{item.period.slice(0,20)}}... <span class="cursor-pointer text-secondary" @click="readMore(item.period)">Read More</span></p>
                        <p v-else class="font-medium text-gray-darkest">{{item.period}}</p>
                      </div>
                    </td>
                    <td class="p-0 text-sm bg-white">
                      <div class="flex items-center h-10 pr-8 font-medium truncate">
                        {{item.currency}}
                      </div>
                    </td>
                    <td class="p-0 text-sm bg-white">
                      <div class="flex items-center justify-end h-10 pr-8 font-medium truncate">
                        {{formatCurrency(item.biayaPerBulan, item.currency)}}
                      </div>
                    </td>
                    <td class="p-0 text-sm bg-white">
                      <div class="flex items-center justify-end h-10 pr-8 font-medium truncate">
                        {{formatCurrency(item.unitPrice, item.currency)}}
                      </div>
                    </td>
                    <td class="p-0 text-sm bg-white">
                      <div class="flex items-center justify-end h-10 pr-8 font-medium truncate">
                        {{formatCurrency(item.totalUnitPrice, item.currency)}}
                      </div>
                    </td>
                    <td class="p-0 text-sm bg-white">
                      <div class="flex items-center justify-end h-10 pr-8 font-medium truncate">
                        {{item.discount}}%
                      </div>
                    </td>
                    <td class="p-0 text-sm bg-white">
                      <div class="flex items-center justify-end h-10 pr-8 font-medium truncate">
                        {{formatCurrency(item.discountPrice, item.currency)}}
                      </div>
                    </td>
                    <td class="p-0 text-sm bg-white">
                      <div class="flex items-center justify-end h-10 pr-8 font-medium truncate">
                        {{formatCurrency(item.finalTotalPrice, item.currency)}}
                      </div>
                    </td>
                  </tr>
                  <tr v-if="n.costType !== RATE_COST" class="w-full h-full bg-gradient-to-t from-primary-lightest to-white">
                    <td class="sticky left-0 z-20 p-0 text-sm" colspan="1"><div class="h-10 border-b-2 border-primary"></div></td>
                    <td class="sticky z-20 p-0 text-sm" :style="`left: ${tdWidth1}px;`" colspan="5">
                      <div class="flex items-center w-full h-10 truncate border-b-2 border-primary">
                        <div class="flex items-center pr-4">
                          <span class="text-base icon-corner-down-right"></span>
                        </div>
                        <p class="pr-8 text-sm font-medium text-gray-darkest">Total Price: <span class="text-sm font-semibold text-primary">{{ formatCurrency(n.totalPrice, totalCurrency(n)) }}</span></p>
                        <p v-if="!vatIncludedChecker(n.taxType)" class="pr-8 text-sm font-medium text-gray-darkest">VAT ({{n.taxTypePercentage}}%): <span class="text-sm font-semibold text-primary">{{ formatCurrency(n.ppn, totalCurrency(n)) }}</span></p>
                        <p v-else class="pr-8 text-sm font-medium text-gray-darkest">{{ n.taxType }}</p>
                        <p class="pr-8 text-sm font-medium text-gray-darkest">Total Price + VAT: <span class="text-sm font-semibold text-primary">{{ formatCurrency(n.grandTotal, totalCurrency(n)) }}</span></p>
                      </div>
                    </td>
                    <td class="p-0" colspan="12"><div class="h-10 border-b-2 border-primary"></div></td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </gista-modal>
    <ModalReadMore @click="closeModal" :isOpen="readMoreShow" :text="modalText"/>
    <ExportToExcel v-if="isOpenExport && menuRfpNegotiationExportXlsx?.canCreate" :isOpenExport="isOpenExport" @toggleExport="toggleExport"/>
  </div>
</template>

<script>
import { formatCurrency } from '@/core/components/helpers/formatRupiah'
import { vatIncludedChecker } from '@/core/components/helpers'
import ModalReadMore from '@/core/components/modal/ReadMore.vue'
import ExportToExcel from '@/core/components/rfp/negotiation/ExportToExcel.vue'
export default {
  name: 'MaximizeTable',
  props: ['isOpen'],
  data() {
    return {
      isOpenExport: false,
      collapse: [],
      modalText: '',
			readMoreShow: false,
      RATE_COST: 'RATE_COST',
      tdWidth1: null,
      tdWidth2: null,
      tdWidth3: null,
      tdWidth4: null,
      maxLength: 34
    }
  },
  computed: {
    menuRfpNegotiationExportXlsx() {
			return this.$store.getters['dashboard/menuRfpNegotiationExportXlsx']
    },
    negotiationDetails() {
			return this.$store.state.negotiation.negotiationDetails
		},
    allCollapsed() {
      return this.collapse.every(() => true)
    }
  },
  watch: {
    negotiationDetails() {
      this.collapse = this.negotiationDetails?.offers?.map((e,i) => (i !== this.negotiationDetails?.offers?.length - 1))
    },
  },
  methods: {
    formatCurrency,
    vatIncludedChecker,
    toggle() {
      this.$emit('clickToggle')
    },
    toggleExport() {
      this.isOpenExport = !this.isOpenExport
    },
    changeCollapse(index) {
      this.collapse = this.collapse.map((e,i) => (i === index? !e : e))
      setTimeout(() => {
        this.resizeWidthTable()
      }, 200)
    },
    closeModal() {
      this.readMoreShow = false
    },
    readMore(text) {
      this.readMoreShow = true;
      this.modalText = text
    },
    totalCurrency(n) {
      return n.offerDetails?.[0]?.currency
    },
    resizeWidthTable() {
      this.tdWidth1 = document.querySelector('.tdWidthClass1')?.offsetWidth
      this.tdWidth2 = document.querySelector('.tdWidthClass2')?.offsetWidth
      this.tdWidth3 = document.querySelector('.tdWidthClass3')?.offsetWidth
      this.tdWidth4 = document.querySelector('.tdWidthClass4')?.offsetWidth
      console.log(this.tdWidth1, this.tdWidth2,this.tdWidth3, this.tdWidth4);
    }
  },
  mounted() {
    console.log('mounted');
    this.collapse = this.negotiationDetails?.offers?.map((e,i) => (i !== this.negotiationDetails?.offers?.length - 1))
    setTimeout(() => {
      this.resizeWidthTable()
    }, 200)
    window.addEventListener('resize', () => this.resizeWidthTable())
  },
  destroyed() {
    console.log('destroyed');
    window.removeEventListener('resize', () => this.resizeWidthTable())
  },
  components: {
    ExportToExcel,
    ModalReadMore
  }
}
</script>

<style scoped>

.height-custom {
  max-height: calc(100% - 4rem);
}
.min-width-custom {
	min-width: 60rem;
}
@media only screen and (max-width: 639px) {
  .height-custom {
    max-height: calc(100% - 8.5rem);
  }
}

</style>
