<template>
  <div>
    <SktLoiLoading v-if="loading.loadingSktLoi"></SktLoiLoading>
    <div v-if="sktLoiShow && !loading.loadingSktLoi">
      <div v-if="draftSktLoi.lastModifiedAt && !isSubmitted && !isRejected && showButtonContinueDraft && menuRfpSktLoi?.canCreate && !mainPage">
        <div class="flex flex-col items-center justify-center gap-12 h-96">
          <img src="@/core/assets/icons/file/others.svg" alt="other" class="w-28" />
          <ButtonGista type="success" class="w-full sm:w-56" @click="() => isOpenContinueDraft = true">Continue Draft</ButtonGista>
          <ModalConfirm 
            title="Lanjutkan Draft?" 
            :message="`Draft terakhir: ${draftSktLoi.lastModifiedAt}`" 
            icon="saved" textButton="Continue Draft" 
            :isOpen="isOpenContinueDraft" 
            @changeIsOpen="() => isOpenContinueDraft = false" 
            @click="setShowButtonContinueDraft(false)"
            colorButton="success">
          </ModalConfirm>
        </div>
      </div>
      <div v-if="!sppProcess">
        <SingleVendorLoi @click="clickNeedToCreateLoi" v-if="menuRfpSktLoi?.canCreate && showNeedToCreateLoi" />
        <CreateSktLoi 
          v-if="(!showButtonContinueDraft || !draftSktLoi.lastModifiedAt) && !showNeedToCreateLoi && !isSubmitted && !isRejected && menuRfpSktLoi?.canCreate && !mainPage"
          @setShowButtonContinueDraft="setShowButtonContinueDraft" 
          ref="createSktLoi"
        />
        <EditSktLoi 
          v-if="menuRfpSktLoi?.canCreate && mainPage === EDIT"
        />
      </div>
      <div v-else-if="!isSubmitted && !isRejected"
        class="flex items-center justify-center height-custom">
        <div class="flex">
          <p class="mr-3 text-2xl icon-alert-circle text-gray"></p>
          <p class="text-base font-medium text-gray">Proses SPP sedang di mulai</p>
        </div>
      </div>
      <SktLoiDetails v-if="(isSubmitted || isRejected) && !mainPage"/>
    </div>
    <div v-if="!sktLoiShow && !loading.loadingSktLoi"
      class="flex items-center justify-center height-custom">
      <div class="flex">
        <p class="mr-3 text-2xl icon-alert-circle text-gray"></p>
        <p class="text-base font-medium text-gray">Proses SKT & LOI belum di mulai</p>
      </div>
    </div>
    <div v-if="sktLoiShow && !menuRfpSktLoi?.canCreate && !completedNoLoi && !isSubmitted && !isRejected && !loading.loadingSktLoi"
      class="flex items-center justify-center height-custom">
      <div class="flex">
        <p class="mr-3 text-2xl icon-alert-circle text-gray"></p>
        <p class="text-base font-medium text-gray">Menunggu kelengkapan form SKT & LOI</p>
      </div>
    </div>
    <div v-if="sktLoiShow && completedNoLoi && !loading.loadingSktLoi" class="flex items-center justify-center height-custom">
      <div class="flex flex-col items-center gap-3">
        <img src="@/core/assets/icons/file/file.svg" alt="icon" class="w-28"/>
        <div v-if="completedNoLoi" class="flex items-center gap-2 border rounded-md py-2.5 px-3.5 border-gray-lightest">
          <span class="text-lg font-medium icon-check-circle text-gray"></span>
          <p class="text-lg font-medium text-gray">LOI will not be created</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SKT_LOI_AWAITING_FOR_CREATION } from '@/core/constant/statusProject'
import CreateSktLoi from '@/core/components/rfp/sktLoi/CreateSktLoi.vue'
import EditSktLoi from '@/core/components/rfp/sktLoi/EditSktLoi.vue'
import SktLoiDetails from '@/core/components/rfp/sktLoi/SktLoiDetails.vue'
import ModalConfirm from '@/core/components/modal/Confirm'
import SktLoiLoading from '@/core/components/rfp/loading/SktLoiLoading.vue';
import SingleVendorLoi from '@/core/components/rfp/sktLoi/SingleVendorLoi.vue'
import { EDIT } from '@/core/constant/procurementApproval.js'

export default {
  name: "SktLoi",
  data() {
    return {
      showButtonContinueDraft: false,
      isOpenContinueDraft: false,
      EDIT
    };
  },
  computed: {
    projectLogRfp() {
      return this.$store.state.projectLog.projectLogRfp
    },
    sktLoiShow() {
      const statusProject = [ SKT_LOI_AWAITING_FOR_CREATION ]
      return this.projectLogRfp?.rfpLogs?.some(e => statusProject.includes(e.statusProject))
    },
    draftSktLoi: {
      get() {
        return this.$store.state.sktLoi.draftSktLoi
      },
      set(value) {
        this.$store.commit('sktLoi/SET_DRAFT_SKT_LOI', value)
      }
    },
    isSubmitted() {
      return !!(this.draftSktLoi.statusDocument === 'SUBMITTED')
    },
    isRejected() {
      return this.draftSktLoi.approvals?.some(e => e.approvalStatus === 'REJECT')
    },
    menuRfpSktLoi() {
			return this.$store.getters['dashboard/menuRfpSktLoi']
		},
    menuRfpSktLoiSubmit() {
			return this.$store.getters['dashboard/menuRfpSktLoiSubmit']
		},
    mainPage() {
      return this.$store.state.sktLoi.mainPage
    },
    loading: {
      get() {
        return this.$store.state.modal.loading
      },
      set(value) {
        this.$store.commit('modal/SET_LOADING', value)
      }
    },
    modal: {
      get() {
        return this.$store.state.modal.modal
      },
      set(value) {
        this.$store.commit('modal/SET_MODAL', value)
      }
    },
    isNew() {
      return !this.draftSktLoi.lastModifiedAt
    },
    isSingleVendor() {
      return this.draftSktLoi.sktVendors?.length === 0
    },
    showNeedToCreateLoi: {
      get() {
        return this.$store.state.sktLoi.showNeedToCreateLoi
      },
      set(value) {
        this.$store.commit('sktLoi/SET_SHOW_NEED_LOI', value)
      }
    },
    completedNoLoi() {
      return !this.draftSktLoi.needLoi && this.isSingleVendor && this.draftSktLoi.isCompleted
    },
		sppProcess() {
			return this.$store.state.spp.sppProcess
		},
    counterInterval() {
			return this.$store.state.projectLog.counterInterval
		},
  },
  methods: {
    setShowButtonContinueDraft(e) {
      this.showButtonContinueDraft = e
    },
    setShowNeedToCreateLoi(e) {
      this.showNeedToCreateLoi = e
    },
    async clickNeedToCreateLoi(val) {
      if(val) this.setShowNeedToCreateLoi(false)
      else {
        this.modal.modalLoading = true
        await this.$store.dispatch('sktLoi/needToCreateLoi', { rfpId: this.$route.params.projectId })
        this.modal.modalLoading = false
        this.$store.dispatch('projectLog/refetchSktLoiLog', { rfpId: this.$route.params.projectId })
      }
    },
    async getSkt() {
      const res2 = await this.$store.dispatch('sktLoi/getSkt', { rfpId: this.$route.params.projectId })
      const res3 = await this.$store.dispatch('procurementApproval/getApproval', { rfpId: this.$route.params.projectId })
      if (res2?.status === 200 || res3?.status === 200) {
        this.loading.loadingSktLoi = false
        if (this.draftSktLoi.lastModifiedAt) {
          this.setShowButtonContinueDraft(true)
        }
      }
    }
  },
  async created() {
    this.loading.loadingProjectLog = true
    this.loading.loadingSktLoi = true
    const res = await this.$store.dispatch('projectLog/getCheckAccess', { projectRequestId: this.$route.params.projectId, projectRequestType: 'rfp' })
    if (res?.data.code !== 200) return
    const res1 = await this.$store.dispatch('projectLog/getProjectLogByRfpId', { rfpId: this.$route.params.projectId })
    await this.$store.dispatch('spp/getSpp', { rfpId: this.$route.params.projectId })
    if (res1?.status === 200) {
      if (this.sktLoiShow) {
        this.getSkt()
      } else {
        this.loading.loadingSktLoi = false
      }
      this.loading.loadingProjectLog = false
    }
  },
  destroyed() {
    this.$store.dispatch('sktLoi/resetState')
    clearInterval(this.counterInterval)
		this.$store.commit('projectLog/SET_COUNTER_INTERVAL', null)
  },
  components:{
    CreateSktLoi,
    SktLoiDetails,
    ModalConfirm,
    EditSktLoi,
    SktLoiLoading,
    SingleVendorLoi 
  }
}
</script>

<style scoped>
.height-custom {
	min-height: 30rem;
}
</style>