<template>
	<div class="w-full height-custom">
		<div v-if="!isLoading">
			<div class="w-full mt-6">
				<div class="flex items-center mb-6">
					<span class="mr-6 icon-file-text"></span>
					<h4 class="text-base font-semibold text-gray-dark">DOCUMENTS</h4>
				</div>
				<div class="flex flex-wrap items-center w-full mb-4">
					<label class="mb-2 mr-2 text-gray">Status Legends:</label>
					<div class="flex px-4 py-1 mb-2 mr-2 text-xs bg-white border rounded-lg text-gray border-gray-light">
						<h5 class="mr-2">Not uploaded yet</h5>
						<span class="text-lg icon-clock"></span>
					</div>
					<div
						class="flex px-4 py-1 mb-2 mr-2 text-xs border rounded-lg bg-warning-lightest text-warning-dark border-warning-dark">
						<h5 class="mr-2">Expired</h5>
						<span class="text-lg icon-x text-warning"></span>
					</div>
					<div
						class="flex px-4 py-1 mb-2 text-xs border rounded-lg bg-success-lightest text-success border-success-light">
						<h5 class="mr-2">Uploaded</h5>
						<span class="text-lg icon-check text-success"></span>
					</div>
	
				</div>
				<div class="w-full">
					<div v-for="(n, i) in qualifications" :key="i">
						<div v-if="showVendor(i)" class="mb-4 border border-gray-lightest rounded-xl">
							<div class="px-4 py-3.5 flex flex-wrap w-full">
								<div class="flex-auto pr-3" :class="projectDetailContainerWidth > 768? 'w-1/4': 'w-full'">
									<div class="flex flex-wrap ">
										<div class="flex items-center mb-1">
											<h6 v-if="!sppProcess" class="mr-1 cursor-pointer text-tertiary hover:text-tertiary-dark" @click="openVendorDetail(n.vendorId, n.isNewVendor)">Vendor #{{ n?.sequence }}</h6>
											<h6 v-else class="mr-1 cursor-not-allowed text-tertiary hover:text-tertiary-dark">Vendor #{{ n?.sequence }}</h6>
											<p class="icon-chevron-right text-tertiary hover:text-teriary-dark"></p>
										</div>
										<div v-if="n.isNewVendor"
											class="flex px-4 py-1 mb-1 text-xs rounded-lg bg-success-lightest text-success">
											<h6>New Vendor</h6>
										</div>
									</div>
									<div>
										<h5 class="font-semibold" >{{ n?.vendorName }}</h5>
										<p class="text-xs break-words text-gray-dark">{{ n?.vendorEmail }}</p>
									</div>
								</div>
								<div class="flex-auto" :class="projectDetailContainerWidth > 768? 'w-2/4': 'w-full'">
									<div class="flex items-center w-full">
										<div class="w-full" :class="projectDetailContainerWidth > 768? 'border-l border-r border-gray-lightest px-3': ''">
											<h6 class="mb-3 text-sm text-gray">Required Documents </h6>
											<div class="flex flex-wrap justify-start w-full">
												<div class="flex px-4 py-1 mb-2 mr-2 text-xs border rounded-lg"
													:class="{
														'bg-success-lightest text-success border-success-light': n.itp01 === 1,
														'bg-white text-gray border-gray-light': n.itp01 === 0,
														'bg-warning-lightest text-warning-dark border-warning-dark': n.itp01 === 2
													}">
													<p class="mr-2">ITP 01 </p>
													<span v-if="n.itp01 === 1"
														class="text-lg icon-check text-success"></span>
													<span v-if="n.itp01 === 2"
														class="text-lg icon-x text-warning"></span>
													<span v-if="n.itp01 === 0"
														class="text-lg icon-clock text-gray"></span>
												</div>
												<div class="flex px-4 py-1 mb-2 mr-2 text-xs border rounded-lg"
													:class="{
														'bg-success-lightest text-success border-success-light': n.itp02 === 1,
														'bg-white text-gray border-gray-light': n.itp02 === 0,
														'bg-warning-lightest text-warning-dark border-warning-dark': n.itp02 === 2
													}">
													<p class="mr-2">ITP 02 </p>
													<span v-if="n.itp02 === 1"
														class="text-lg icon-check text-success"></span>
													<span v-if="n.itp02 === 2"
														class="text-lg icon-x text-warning"></span>
													<span v-if="n.itp02 === 0"
														class="text-lg icon-clock text-gray"></span>
												</div>
												<div class="flex px-4 py-1 mb-2 mr-2 text-xs border rounded-lg"
													:class="{
														'bg-success-lightest text-success border-success-light': n.isSentItp03,
														'bg-white text-gray border-gray-light': !n.isSentItp03
													}">
													<p class="mr-2">ITP 03 </p>
													<span v-if="n.isSentItp03"
														class="text-lg icon-check text-success"></span>
													<span v-if="!n.isSentItp03"
														class="text-lg icon-clock text-gray"></span>
												</div>
												<div class="flex px-4 py-1 mb-2 mr-2 text-xs border rounded-lg"
													:class="{
														'bg-success-lightest text-success border-success-light': n.checklistDoc === 1,
														'bg-white text-gray border-gray-light': n.checklistDoc === 0,
														'bg-warning-lightest text-warning-dark border-warning-dark': n.checklistDoc === 2
													}">
													<p class="mr-2">CHECKLIST</p>
													<span v-if="n.checklistDoc === 1"
														class="text-lg icon-check text-success"></span>
													<span v-if="n.checklistDoc === 2"
														class="text-lg icon-x text-warning"></span>
													<span v-if="n.checklistDoc === 0"
														class="text-lg icon-clock text-gray"></span>
												</div>
												<div class="flex px-4 py-1 mb-2 mr-2 text-xs border rounded-lg"
													:class="{
														'bg-success-lightest text-success border-success-light': n.pendaftaranExternal === 1,
														'bg-white text-gray border-gray-light': n.pendaftaranExternal === 0,
														'bg-warning-lightest text-warning-dark border-warning-dark': n.pendaftaranExternal === 2
													}">
													<p class="mr-2">PENDAFTARAN</p>
													<span v-if="n.pendaftaranExternal === 1"
														class="text-lg icon-check text-success"></span>
													<span v-if="n.pendaftaranExternal === 2"
														class="text-lg icon-x text-warning"></span>
													<span v-if="n.pendaftaranExternal === 0"
														class="text-lg icon-clock text-gray"></span>
												</div>
												<div class="flex px-4 py-1 mb-2 mr-2 text-xs border rounded-lg"
													:class="{
														'bg-success-lightest text-success border-success-light': n.pernyataanKepatuhan === 1,
														'bg-white text-gray border-gray-light': n.pernyataanKepatuhan === 0,
														'bg-warning-lightest text-warning-dark border-warning-dark': n.pernyataanKepatuhan === 2
													}">
													<p class="mr-2">PERNYATAAN</p>
													<span v-if="n.pernyataanKepatuhan === 1"
														class="text-lg icon-check text-success"></span>
													<span v-if="n.pernyataanKepatuhan === 2"
														class="text-lg icon-x text-warning"></span>
													<span v-if="n.pernyataanKepatuhan === 0"
														class="text-lg icon-clock text-gray"></span>
												</div>
												<div class="flex px-4 py-1 mb-2 mr-2 text-xs border rounded-lg"
													:class="{
														'bg-success-lightest text-success border-success-light': n.isSentDueDiligence,
														'bg-white text-gray border-gray-light': !n.isSentDueDiligence
													}">
													<p class="mr-2">DUE DILIGENCE</p>
													<span v-if="n.isSentDueDiligence"
														class="text-lg icon-check text-success"></span>
													<span v-if="!n.isSentDueDiligence"
														class="text-lg icon-clock text-gray"></span>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="flex flex-wrap items-center justify-center flex-auto" :class="projectDetailContainerWidth > 768? 'w-1/4 px-3': 'w-full'">
									<div class="flex items-center justify-center" :class="[projectDetailContainerWidth > 768? 'flex-col': 'flex-wrap', projectDetailContainerWidth > 640? '': 'w-full']">
										<SendEmail v-if="menuSendRFI?.canCreate && n.statusVendor !== QUALIFICATION_ELIMINATED && n.statusVendor !== SCORING_DECISION_ELIMINATED && !projectCanceledCompleted" class="flex items-center justify-center my-3" :class="projectDetailContainerWidth > 768? '': 'w-full'" :qualification="n" />
										<EliminateDocuments v-if="menuQualificationActionDisqualified?.canUpdate && showEliminate(n) && !projectCanceledCompleted"
											:class="projectDetailContainerWidth > 768? 'mb-3': 'w-full mb-3'"
											:qualification="n"
											:index="i"
											:rfpId="rfpId"
											:praQualification="true" />
									</div>
								</div>
							</div>
							<!-- split -->
							<div v-for="(local, j) in n.localPartners" :key="j" class="px-4 py-3.5 flex flex-wrap w-full border-t-2 border-dashed border-gray-lightest" :class="j === n.localPartners.length - 1? 'rounded-b-lg': ''">
								<div class="flex-auto pr-3" :class="projectDetailContainerWidth > 768? 'w-1/4': 'w-full'">
									<div class="flex flex-wrap ">
										<div class="flex items-center mb-1">
											<h6 v-if="!sppProcess" class="mr-1 cursor-pointer text-tertiary hover:text-tertiary-dark" @click="openVendorDetail(local.vendorId, local.isNewVendor)">Local Partner #{{ local?.sequence }}</h6>
											<h6 v-else class="mr-1 cursor-not-allowed text-tertiary hover:text-tertiary-dark">Local Partner #{{ local?.sequence }}</h6>
											<p class="icon-chevron-right text-tertiary hover:text-teriary-dark"></p>
										</div>
										<div v-if="local.isNewVendor"
											class="flex px-4 py-1 mb-1 text-xs rounded-lg bg-success-lightest text-success">
											<h6>New Vendor</h6>
										</div>
									</div>
									<div>
										<h5 class="font-semibold" >{{ local?.vendorName }}</h5>
										<p class="text-xs break-words text-gray-dark">{{ local?.vendorEmail }}</p>
									</div>
								</div>
								<div class="flex-auto" :class="projectDetailContainerWidth > 768? 'w-2/4': 'w-full'">
									<div class="flex items-center w-full">
										<div class="w-full" :class="projectDetailContainerWidth > 768? 'border-l border-r border-gray-lightest px-3': ''">
											<h6 class="mb-3 text-sm text-gray">Required Documents </h6>
											<div class="flex flex-wrap justify-start w-full">
												
												<div class="flex px-4 py-1 mb-2 mr-2 text-xs border rounded-lg"
													:class="{
														'bg-success-lightest text-success border-success-light': local.checklistDoc === 1,
														'bg-white text-gray border-gray-light': local.checklistDoc === 0,
														'bg-warning-lightest text-warning-dark border-warning-dark': local.checklistDoc === 2
													}">
													<p class="mr-2">CHECKLIST</p>
													<span v-if="local.checklistDoc === 1"
														class="text-lg icon-check text-success"></span>
													<span v-if="local.checklistDoc === 2"
														class="text-lg icon-x text-warning"></span>
													<span v-if="local.checklistDoc === 0"
														class="text-lg icon-clock text-gray"></span>
												</div>
												<div class="flex px-4 py-1 mb-2 mr-2 text-xs border rounded-lg"
													:class="{
														'bg-success-lightest text-success border-success-light': local.pendaftaranExternal === 1,
														'bg-white text-gray border-gray-light': local.pendaftaranExternal === 0,
														'bg-warning-lightest text-warning-dark border-warning-dark': local.pendaftaranExternal === 2
													}">
													<p class="mr-2">PENDAFTARAN</p>
													<span v-if="local.pendaftaranExternal === 1"
														class="text-lg icon-check text-success"></span>
													<span v-if="local.pendaftaranExternal === 2"
														class="text-lg icon-x text-warning"></span>
													<span v-if="local.pendaftaranExternal === 0"
														class="text-lg icon-clock text-gray"></span>
												</div>
												<div class="flex px-4 py-1 mb-2 mr-2 text-xs border rounded-lg"
													:class="{
														'bg-success-lightest text-success border-success-light': local.pernyataanKepatuhan === 1,
														'bg-white text-gray border-gray-light': local.pernyataanKepatuhan === 0,
														'bg-warning-lightest text-warning-dark border-warning-dark': local.pernyataanKepatuhan === 2
													}">
													<p class="mr-2">PERNYATAAN</p>
													<span v-if="local.pernyataanKepatuhan === 1"
														class="text-lg icon-check text-success"></span>
													<span v-if="local.pernyataanKepatuhan === 2"
														class="text-lg icon-x text-warning"></span>
													<span v-if="local.pernyataanKepatuhan === 0"
														class="text-lg icon-clock text-gray"></span>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="flex flex-wrap items-center justify-center flex-auto" :class="[projectDetailContainerWidth > 768? 'w-1/4 px-3': '', projectDetailContainerWidth < 640 ? 'w-full' : '']">
									<div v-if="local?.statusVendor !== PRA_QUALIFICATION_ELIMINATED" class="flex items-center justify-center" :class="[projectDetailContainerWidth > 768? 'flex-col': 'flex-wrap', projectDetailContainerWidth > 640? '': 'w-full']">
										<SendEmail 
											v-if="menuSendRFI?.canCreate && local.statusVendor !== QUALIFICATION_ELIMINATED && local.statusVendor !== SCORING_DECISION_ELIMINATED && !projectCanceledCompleted" 
											class="flex items-center justify-center my-3" 
											:qualification="local" 
											:class="projectDetailContainerWidth > 768 ? '': 'w-full'" 
											:isLocalVendor="true"
										/>
										<EliminateDocuments 
											v-if="menuQualificationActionDisqualified?.canUpdate && showEliminate(local) && !projectCanceledCompleted"
											class="mb-3"
											:qualification="local"
											:rfpId="rfpId"
											:index="j" 
											:praQualification="true"
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
					<GistaModal 
						:value="modalVendorDetail"
						width="2/3"
						scrollable
						@input="closeModalVendorDetail"
						customClass="bg-white top-0 bottom-0 mt-6 mb-8 max-w-7xl">
						<VendorDetail @closeModal="closeModalVendorDetail" :vendor="modalVendorProps" :projectId="proposalInformation.projectId" ></VendorDetail>
					</GistaModal>
					<gista-modal :value="readFullShow"
						customClass="max-w-2xl bg-white"
						:scrollable="true"
						@input="closeModal">
						<div class="w-full p-6">
							<div class="flex p-3 pr-10">
								<p class="text-sm font-medium break-words whitespace-pre-wrap text-gray">{{disqualifiedReason}}</p>
							</div>
						</div>
					</gista-modal>
				</div>
			</div>
			<!-- send rfp confirmation -->
			<div class="flex justify-between mb-6" :class="projectDetailContainerWidth < 640 ? 'flex-col' : 'flex-row'" v-if="sendRFPConfirmationShow">
				<div class="flex items-center">
					<p class="mr-3 text-2xl icon-info text-warning"></p>
					<p class="mr-4 text-sm text-warning">Silakan kirim dan unggah dokumen yang diperlukan untuk setiap kandidat vendor</p>
				</div>
				<SendRFPConfirmation v-if="menuQualificationActionSendRfp?.canCreate && !sppProcess" />
			</div>
		</div>
		<PraQualificationLoading v-else/>
	</div>
</template>
<script>
import SendEmail from '@/core/components/modal/SendEmail.vue';
import EliminateDocuments from '@/core/components/modal/EliminateDocuments.vue';
import SendRFPConfirmation from '@/core/components/modal/SendRFPConfirmation.vue';
import VendorDetail from '@/core/components/vendors/VendorDetail'
import { CANDIDATE, RFP_SENT, PROPOSAL_WAITING_CHANGES, PROPOSAL_REJECTED, QUALIFICATION_ELIMINATED, PRA_QUALIFICATION_ELIMINATED, SCORING_DECISION_ELIMINATED  } from '@/core/constant/qualification.js'
import PraQualificationLoading from '@/core/components/rfp/loading/PraQualificationLoading.vue';
import { CANCELED, COMPLETED } from '@/core/constant/statusProject'

export default {
	name: "PraQualification",
	data() {
		return {
			modalVendorDetail: false,
			modalVendorProps: {
				vendorId: '',
				isNewVendor: true,
			},
			isLoading: true,
			readFullShow: false,
			disqualifiedReason: '',
			QUALIFICATION_ELIMINATED,
			PRA_QUALIFICATION_ELIMINATED,
			SCORING_DECISION_ELIMINATED
		}
	},
	computed: {
		qualifications: {
			get() {
				return this.$store.state.qualification.qualifications
			},
			set(value) {
				this.$store.commit('qualification/SET_QUALIFICATIONS', value)
			}
		},
		rfpId() {
			return this.$store.state.qualification.rfpId
		},
		loading: {
			get() {
				return this.$store.state.modal.loading
			},
			set(value) {
				this.$store.commit('modal/SET_LOADING', value)
			}
		},
		menuSendRFI() {
			return this.$store.getters['dashboard/menuSendRFI']
		},
		menuQualificationActionDisqualified() {
			return this.$store.getters['dashboard/menuQualificationActionDisqualified']
		},
		menuQualificationActionSendRfp() {
			return this.$store.getters['dashboard/menuQualificationActionSendRfp']
		},
		projectDetailContainerWidth() {
			return this.$store.state.width.projectDetailContainerWidth
		},
		proposalInformation() {
      return this.$store.state.projectDetail.proposalInformation
    },
    sendRFPConfirmationShow() {
			return this.qualifications.some(e => e.statusVendor === CANDIDATE || e.localPartners.some(el => el.statusVendor === CANDIDATE))
		},
		modal: {
      get() {
        return this.$store.state.modal.modal
      },
      set(value) {
        this.$store.commit('modal/SET_MODAL', value)
      }
    },
		projectLogRfp() {
      return this.$store.state.projectLog.projectLogRfp
    },
		projectCanceledCompletedbyLogs() {
			return this.projectLogRfp.rfpLogs.some(e => e.statusProject === CANCELED || e.statusProject === COMPLETED)
		},
    projectCanceledCompleted() {
			return this.sppProcess || this.projectCanceledCompletedbyLogs
		},
		sppProcess() {
			return this.$store.state.spp.sppProcess
		},
	},
	methods: {
		openVendorDetail(vendorId, isNewVendor) {
			this.modalVendorProps = { vendorId: vendorId, isNewVendor: isNewVendor }
			this.modalVendorDetail = !this.modalVendorDetail
		},
		closeModalVendorDetail() {
			this.modalVendorDetail = !this.modalVendorDetail
			this.$store.dispatch('qualification/getQualifications', this.$route.params.projectId)
		},
		showVendorDocuments(i) {
			return this.qualifications[i].statusVendor === CANDIDATE
		},
		showEliminate(vendor) {
			const statusVendor = [CANDIDATE]
			return statusVendor.includes(vendor.statusVendor)
		},
		allLocalPartnerNotUploaded(vendor) {
			const statusVendor = [CANDIDATE, RFP_SENT, PROPOSAL_REJECTED, PROPOSAL_WAITING_CHANGES]
			return vendor.localPartners.some(e => statusVendor.includes(e.statusVendor))
		},
		showVendor(i) {
			return this.qualifications[i].statusVendor !== PRA_QUALIFICATION_ELIMINATED || this.qualifications[i].statusVendor === SCORING_DECISION_ELIMINATED
		},
		closeModal() {
			this.readFullShow = false
		},
		readFull(disqualifiedReason) {
			this.disqualifiedReason = disqualifiedReason
			this.readFullShow = true
		},
		async viewAttachment(filename, documentNo) {
      this.$store.dispatch('file/viewAttachment', { filename, documentNo })
		},
	},
  async mounted() {
		this.isLoading = true
		this.loading.loadingProjectLog = true
		const res = await this.$store.dispatch('projectLog/getCheckAccess', { projectRequestId: this.$route.params.projectId, projectRequestType: 'rfp' })
		console.log(res, res?.data.code, 'get access');
    if (res?.data.code !== 200 || !this.menuQualificationActionSendRfp?.canCreate) {
			this.$store.commit('projectLog/SET_CAN_ACCESS', false)
			return
		} 
    const r1 = this.$store.dispatch('projectDetail/getProposalInformation', this.$route.params.projectId)
    const r2 = this.$store.dispatch('qualification/getQualifications', this.$route.params.projectId)
    const r3 = this.$store.dispatch('projectLog/getProjectLogByRfpId', { rfpId: this.$route.params.projectId })
		const [res1, res2, res3] = await Promise.all([r1, r2, r3])
    await this.$store.dispatch('spp/getSpp', { rfpId: this.$route.params.projectId })
		if (res1?.status === 200 && res2?.status === 200) {
			this.isLoading = false
		}
		if (res3?.status === 200) {
			this.loading.loadingProjectLog = false
		}
  },
	components: {
    SendEmail,
    EliminateDocuments,
    VendorDetail,
    SendRFPConfirmation,
    PraQualificationLoading,
	}
}

</script>

<style scoped>
.height-custom {
	min-height: 30rem;
}
</style>