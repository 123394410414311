<template>
	<div class="">
		<div class="w-full overflow-auto max-h-custom">
			<div class="w-full">
				<table class="relative w-full border-collapse" aria-describedby="negotiationTableDetails">
					<thead class="sticky top-0 z-20 h-10 py-1 bg-white">
						<tr class="truncate">
							<th class="p-0" scope="col">
								<div class="h-10 border-b-2 border-primary"></div>
							</th>
							<th class="p-0" scope="col">
								<h5 class="flex items-center h-10 pr-8 text-sm font-medium text-left border-b-2 text-gray-darkest border-primary">No</h5>
							</th>
							<th class="p-0" scope="col">
								<h5 class="flex items-center h-10 pr-8 text-sm font-medium text-left border-b-2 border-primary text-gray-darkest">ID Product</h5>
							</th>
							<th class="p-0" scope="col">
								<h5 class="flex items-center h-10 pr-8 text-sm font-medium text-left border-b-2 border-primary text-gray-darkest">Type</h5>
							</th>
							<th class="p-0" scope="col">
								<h5 class="flex items-center h-10 pr-8 text-sm font-medium text-left border-b-2 border-primary text-gray-darkest">Product/Service</h5>
							</th>
							<th class="p-0" scope="col">
								<h5 class="flex items-center h-10 pr-8 text-sm font-medium text-left border-b-2 border-primary text-gray-darkest">Sub Product</h5>
							</th>
							<th class="p-0" scope="col">
								<h5 class="flex items-center h-10 pr-8 text-sm font-medium text-left border-b-2 border-primary text-gray-darkest">Description</h5>
							</th>
							<th class="p-0" scope="col">
								<h5 class="flex items-center h-10 pr-8 text-sm font-medium text-left border-b-2 border-primary text-gray-darkest">Location</h5>
							</th>
							<th class="p-0" scope="col">
								<h5 class="flex items-center h-10 pr-8 text-sm font-medium text-left border-b-2 border-primary text-gray-darkest">Qty</h5>
							</th>
							<th class="p-0" scope="col">
								<h5 class="flex items-center h-10 pr-8 text-sm font-medium text-left border-b-2 border-primary text-gray-darkest">UoM</h5>
							</th>
							<th class="p-0" scope="col">
								<h5 class="flex items-center h-10 pr-8 text-sm font-medium text-left border-b-2 border-primary text-gray-darkest">Period</h5>
							</th>
							<th class="p-0" scope="col">
								<h5 class="flex items-center h-10 pr-8 text-sm font-medium text-left border-b-2 border-primary text-gray-darkest">Currency</h5>
							</th>
							<th class="p-0" scope="col">
								<h5 class="flex items-center justify-center h-10 pr-8 text-sm font-medium text-center border-b-2 border-primary text-gray-darkest">Monthly Cost</h5>
							</th>
							<th class="p-0" scope="col">
								<h5 class="flex items-center justify-center h-10 pr-8 text-sm font-medium text-center border-b-2 border-primary text-gray-darkest">Unit Price</h5>
							</th>
							<th class="p-0" scope="col">
								<h5 class="flex items-center justify-center h-10 pr-8 text-sm font-medium text-center border-b-2 border-primary text-gray-darkest">Total Unit Price</h5>
							</th>
							<th class="p-0" scope="col">
								<h5 class="flex items-center justify-center h-10 pr-8 text-sm font-medium text-center border-b-2 border-primary text-gray-darkest">Disc %</h5>
							</th>
							<th class="p-0" scope="col">
								<h5 class="flex items-center justify-center h-10 pr-8 text-sm font-medium text-center border-b-2 border-primary text-gray-darkest">Disc Price</h5>
							</th>
							<th class="p-0" scope="col">
								<h5 class="flex items-center justify-center h-10 pr-8 text-sm font-medium text-center border-b-2 border-primary text-gray-darkest">Final Total Price</h5>
							</th>
						</tr>
					</thead>
					<tbody v-for="n,i in negotiationOfferItems" :key="i">
						<template >
							<tr class="sticky z-10 h-10 py-1 cursor-pointer top-10 bg-primary-lightest" 
								@click="toggleCollapse(i)">
								<td class="w-max py-2 px-2.5 cursor-pointer" colspan="1">
									<span v-if="!collapse[i]"
										class="mr-3 text-base font-bold cursor-pointer icon-plus text-gray"></span>
									<span v-else
										class="mr-3 text-base font-bold cursor-pointer icon-minus text-gray"></span>
								</td>
								<td class="relative" colspan="17">
									<div class="absolute top-0 flex items-center h-10">
										<h5 class="mr-4 text-sm text-gray-darkest">{{n.title}}</h5>
										<TooltipSolutip :dataTitle="`Uploaded Date: ${n.dateUploaded}`">
											<p class="mr-4 text-lg icon-info text-gray"></p>
										</TooltipSolutip>
									</div>
								</td>
							</tr>
							<template v-for="(item, j) in n?.offerDetails">
								<tr v-show="collapse[i]" class="h-10 truncate" v-if="item" :key="j">
									<td class="w-max flex items-center px-2.5 ">
										<span class="mr-3 text-base font-bold text-white icon-plus"></span>
									</td>
									<td>
										<h5 class="pr-8 text-sm text-gray-darkest">{{ j+1 }}</h5>
									</td>
									<td>
										<h5 v-if="item.productId?.length > maxLength" class="pr-8 text-sm text-gray-darkest">{{ item.productId.slice(0, 20) }}... <span class="cursor-pointer text-secondary" @click="readMore(item.productId)">Read More</span></h5>
										<h5 v-else class="pr-8 text-sm text-gray-darkest">{{ item.productId }}</h5>
									</td>
									<td>
										<h5 v-if="item.jenis?.length > maxLength" class="pr-8 text-sm text-gray-darkest">{{ item.jenis.slice(0, 20) }}... <span class="cursor-pointer text-secondary" @click="readMore(item.jenis)">Read More</span></h5>
										<h5 v-else class="pr-8 text-sm text-gray-darkest">{{ item.jenis }}</h5>
									</td>
									<td>
										<h5 v-if="item.productLayanan?.length > maxLength" class="pr-8 text-sm text-gray-darkest">{{ item.productLayanan.slice(0, 20) }}... <span class="cursor-pointer text-secondary" @click="readMore(item.productLayanan)">Read More</span></h5>
										<h5 v-else class="pr-8 text-sm text-gray-darkest">{{ item.productLayanan }}</h5>
									</td>
									<td>
										<h5 v-if="item.subProduct?.length > maxLength" class="pr-8 text-sm text-gray-darkest">{{ item.subProduct.slice(0, 20) }}... <span class="cursor-pointer text-secondary" @click="readMore(item.subProduct)">Read More</span></h5>
										<h5 v-else class="pr-8 text-sm text-gray-darkest">{{ item.subProduct }}</h5>
									</td>
									<td>
										<h5 v-if="item.description?.length > maxLength" class="pr-8 text-sm text-gray-darkest">{{ item.description.slice(0,20) }}... <span class="cursor-pointer text-secondary" @click="readMore(item.description)">Read More</span></h5>
										<h5 v-else class="pr-8 text-sm text-gray-darkest">{{ item.description }}</h5>
									</td>
									<td>
										<h5 v-if="item.location?.length > maxLength" class="pr-8 text-sm text-gray-darkest">{{ item.location.slice(0, 20) }}... <span class="cursor-pointer text-secondary" @click="readMore(item.location)">Read More</span></h5>
										<h5 v-else class="pr-8 text-sm text-gray-darkest">{{ item.location }}</h5>
									</td>
									<td>
										<h5 class="pr-8 text-sm text-gray-darkest">{{ item.qty }}</h5>
									</td>
									<td>
										<h5 v-if="item.uom?.length > maxLength" class="pr-8 text-sm text-gray-darkest">{{ item.uom.slice(0, 20) }}... <span class="cursor-pointer text-secondary" @click="readMore(item.uom)">Read More</span></h5>
										<h5 v-else class="pr-8 text-sm text-gray-darkest">{{ item.uom }}</h5>
									</td>
									<td>
										<h5 v-if="item.period?.length > maxLength" class="pr-8 text-sm text-gray-darkest">{{ item.period.slice(0, 20) }}... <span class="cursor-pointer text-secondary" @click="readMore(item.period)">Read More</span></h5>
										<h5 v-else class="pr-8 text-sm text-gray-darkest">{{ item.period }}</h5>
									</td>
									<td>
										<h5 class="pr-8 text-sm text-gray-darkest">{{ item.currency }}</h5>
									</td>
									<td>
										<h5 class="pr-8 text-sm text-right text-gray-darkest">{{ formatCurrency(item.biayaPerBulan, item.currency) }}</h5>
									</td>
									<td>
										<h5 class="pr-8 text-sm text-right text-gray-darkest">{{ formatCurrency(item.unitPrice, item.currency) }}</h5>
									</td>
									<td>
										<h5 class="pr-8 text-sm text-right text-gray-darkest">{{ formatCurrency(item.totalUnitPrice, item.currency) }}</h5>
									</td>
									<td>
										<h5 class="pr-8 text-sm text-right text-gray-darkest">{{ item.discount }}%</h5>
									</td>
									<td>
										<h5 class="pr-8 text-sm text-right text-gray-darkest">{{ formatCurrency(item.discountPrice, item.currency) }}</h5>
									</td>
									<td>
										<h5 class="pr-8 text-sm text-right text-gray-darkest">{{ formatCurrency(item.finalTotalPrice, item.currency) }}</h5>
									</td>
								</tr>
							</template>
							<template v-if="n.costType !== RATE_COST">
								<tr v-show="collapse[i]" class="bg-gradient-to-t from-primary-lightest to-white">
									<td class="p-0"><div class="h-10 border-b-2 border-primary"></div></td>
									<td class="p-0">
										<div class="flex items-center h-10 border-b-2 border-primary">
											<span class="text-base icon-corner-down-right"></span>
										</div>
									</td>
									<td class="p-0" colspan="17">
										<div class="flex items-center h-10 border-b-2 border-primary">
											<p class="mr-12 text-sm font-medium text-gray-darkest">Total Price: <span class="text-sm font-semibold text-primary">{{ formatCurrency(n?.totalPrice, totalCurrency(n)) }}</span></p>
											<p v-if="!vatIncludedChecker(n?.taxType)" class="mr-12 text-sm font-medium text-gray-darkest">VAT ({{n?.taxTypePercentage}}%): <span class="text-sm font-semibold text-primary">{{ formatCurrency(n?.ppn, totalCurrency(n)) }}</span></p>
											<p v-else class="mr-12 text-sm font-medium text-gray-darkest">{{ n?.taxType }}</p>
											<p class="mr-12 text-sm font-medium text-gray-darkest">Total Price + VAT: <span class="text-sm font-semibold text-primary">{{formatCurrency(n?.grandTotal, totalCurrency(n)) }}</span></p>
										</div>
									</td>
								</tr>
							</template>
						</template>
					</tbody>
				</table>
				<ModalReadMore @click="closeModal" :isOpen="readMoreShow" :text="modalText"/>
			</div>
		</div>
	</div>
</template>

<script>
import { formatCurrency } from '@/core/components/helpers/formatRupiah'
import { vatIncludedChecker } from '@/core/components/helpers'
import TooltipSolutip from '@/core/components/custom/Tooltip.vue'
import ModalReadMore from '@/core/components/modal/ReadMore.vue'

export default {
	name: 'NegotiationTableDetail',
	data() {
		return {
			collapse: [],
			modalText: '',
			readMoreShow: false,
			RATE_COST: 'RATE_COST',
			maxLength: 34
		}
	},
	computed: {
		negotiationDetails() {
			return this.$store.state.negotiation.negotiationDetails
		},
		negotiationOfferItems() {
      return this.$store.state.negotiation.negotiationOfferItems
    },
	},
  watch: {
    negotiationOfferItems() {
      this.collapse = this.negotiationOfferItems?.map((e,i) => (i === this.negotiationOfferItems?.length - 1))
    }
  },
	methods: {
		formatCurrency,
    vatIncludedChecker,
		toggleCollapse(index) {
			this.collapse = this.collapse.map((e,i) => (i === index? !e : e))
		},
		closeModal() {
			this.readMoreShow = false
		},
		readMore(text) {
			this.readMoreShow = true;
			this.modalText = text
		},
		totalCurrency(n) {
			return n?.offerDetails?.[0]?.currency
		}
	},
	mounted() {
		this.collapse = this.negotiationOfferItems?.map((e,i) => (i === this.negotiationOfferItems?.length - 1))
	},
	components: {
		TooltipSolutip,
		ModalReadMore
	}


}
</script>

<style scoped>
.max-h-custom {
	max-height: 28rem;
}
</style>